exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cup-elem-js": () => import("./../../../src/pages/cup_elem.js" /* webpackChunkName: "component---src-pages-cup-elem-js" */),
  "component---src-pages-cups-js": () => import("./../../../src/pages/cups.js" /* webpackChunkName: "component---src-pages-cups-js" */),
  "component---src-pages-cups-list-js": () => import("./../../../src/pages/cups_list.js" /* webpackChunkName: "component---src-pages-cups-list-js" */),
  "component---src-pages-glass-elem-js": () => import("./../../../src/pages/glass_elem.js" /* webpackChunkName: "component---src-pages-glass-elem-js" */),
  "component---src-pages-glass-js": () => import("./../../../src/pages/glass.js" /* webpackChunkName: "component---src-pages-glass-js" */),
  "component---src-pages-glass-list-js": () => import("./../../../src/pages/glass_list.js" /* webpackChunkName: "component---src-pages-glass-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-looking-js": () => import("./../../../src/pages/looking.js" /* webpackChunkName: "component---src-pages-looking-js" */),
  "component---src-pages-newest-js": () => import("./../../../src/pages/newest.js" /* webpackChunkName: "component---src-pages-newest-js" */)
}

